import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'MENUITEMS.MENU.TEXT',
        isTitle: true,
        role: [ "admin"]
     },
    {
        id: 2,
        label: 'MENUITEMS.DASHBOARDS.TEXT',
        icon: 'bx-home-circle',
        link: '/dashboard',
        role: [ "admin", "commercial"]
    },
    
    {
        id: 7,
        isLayout: true,
        role: [ "admin"]
    },
    {
        id: 8,
        label: 'MENUITEMS.APPS.TEXT',
        isTitle: true,
        role: [ "admin"]
    },
    {
        id: 3,
        label: 'MENUITEMS.SETTINGS.TEXT',
        icon: 'bx-home-circle',
        link: '/settings',
        role: [ "admin"]
    },
    {
        id: 9,
        label: 'MENUITEMS.ADMIN.TEXT',
        icon: 'bx-chat',
        link: '/admin',
        role: [ "admin"]
    }
    ,
    
    {
        id: 11,
        label: 'MENUITEMS.CONTACT.TEXT',
        icon: 'bxs-user-detail',
        link: '/commercial',
        role: [ "admin"]

    },
     
    {
        id: 12,
        label: 'MENUITEMS.CHANTIER.TEXT',
        icon: 'bx bx-cog',
        link: '/chantier',
        role: ["admin" , "commercial"]

    },
    {
        id:13,
        label: 'MENUITEMS.MARGE.TEXT',
        icon: 'bx bx-user-circle',
        link: '/marge',
        role: ["admin","commercial"]
         
    }
    ,
    {
        id:14,
        label: 'MENUITEMS.ESTIMATION.TEXT',
        icon: 'bx bx-user-check ',
        link: '/estimation',
        role: ["admin","commercial"]
         
    } ,
    {
        id:15,
        label: 'MENUITEMS.CLIENT.TEXT',
        icon: 'bx bx-user ',
        link: '/client',
        role: ["admin"]
         
    }
]; 


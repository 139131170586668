import { Component , OnInit,HostBinding} from '@angular/core';
 import { FormControl } from '@angular/forms';
 import { OverlayContainer } from '@angular/cdk/overlay';
 import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  @HostBinding('class') className = '';
  toggleControl = new FormControl(false);
  decodeToken: any;

  
  constructor(  private overlay: OverlayContainer,private jwtHelper: JwtHelperService) { }

  ngOnInit() {
    // document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
    this.toggleControl.valueChanges.subscribe((darkMode) => {
      const darkClassName = 'darkMode';
      this.className = darkMode ? darkClassName : '';
      if (darkMode) {
        this.overlay.getContainerElement().classList.add(darkClassName);
      } else {
        this.overlay.getContainerElement().classList.remove(darkClassName);
      }
    });
    this.getUser()
  }
  getUser(){
 
     this.decodeToken = this.jwtHelper.decodeToken(localStorage.getItem("token"));
      console.log("  var token =",this.decodeToken )
    return this.decodeToken 
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
  })

export class MainPageService {


    constructor(private http: HttpClient) {
    }

    getConnectUser() {

        return this.http.get(`${environment.apiURL}/user/bytoken`)  
    }
    
    updateUserProfileImg(image) {
        const fd = new FormData()
        fd.append('profileImage', image)
        return this.http.patch(`${environment.apiURL}/user/image/`, fd)    
    }
    disconnectUser(userId) {
        localStorage.clear()
        return this.http.patch(`${environment.apiURL}/user/password/`, { userId })   
    }   
    updateUserPassword(oldPassword, newPassword) {
        return this.http.patch(`${environment.apiURL}/user/password/`, { oldPassword, newPassword })     
    }
    updateUserInfo(user) {
        return this.http.patch(`${environment.apiURL}/user/update`, { user })
    }
}
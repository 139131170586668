import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, BehaviorSubject, Subject } from 'rxjs';
 import { map } from 'rxjs/operators';
import { Profile } from 'src/app/models/Profile';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

    token: string;
    constructor(private http: HttpClient) {
        this.token = localStorage.getItem('token')
     //const time_to_login =  3600; // one week
     //localStorage.setItem('timer', JSON.stringify(time_to_login));
    }
/* 
    setSesstion(token) {  
        this.token = token;
        localStorage.setItem('token', token)
        if (this.token) this.socket.emit('connectuser', this.token, window.location.href.includes('room'))
    } */

    getToken() {
        return this.token;
    }

    userLogin(username: string, password: string) {

        return this.http.post<any>(`${environment.apiURL}/user/login`, { username, password } ).pipe(map(response => {
            
            // login successful if there's a jwt token in the response
            if (response.user && response.token) {
              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('currentUser', JSON.stringify(response.user));
              localStorage.setItem('token', response.token);
              //this.currentUserSubject.next(res);
             }
            return response;
          }));
    }

    userRegistration(data:FormData): Observable<any> {
        return this.http.post<any>(`${environment.apiURL}/user`, data)
     }

    getCurentUser2() {
      
      const user = localStorage.getItem('currentUser');
      if (user) {
        return JSON.parse(user);
      }
      return {};
      }

      getCurentUser() {

        if (localStorage.getItem('currentUser') === "undefined") {
          console.log("Item does not exist in localstoarge");
        }
        else {
          var myStr = localStorage.getItem('currentUser');
          if (myStr === null || myStr === 'undefined') {
            myStr = "";
          }
          return myStr;
    
        }
    
      }
      disconnectUser() {
        const headers = new HttpHeaders().set('Authorization', this.token);
        this.token = null;
        localStorage.clear()
        return this.http.patch(`${environment.apiURL}/user/disconnect`, null, { headers: headers })  
    }



    private profiles:   [];
    private profiles$ = new Subject<any[]>();
    readonly url = `${environment.apiURL}/api/profiles`;   
  
   
    getProfiles() {
      this.http
        .get<{ profiles: Profile[] }>(this.url)
        .pipe(
          map((profileData) => {
            return profileData.profiles;
          })
        )
        .subscribe(() => {
           this.profiles$.next(this.profiles);
        });
    }
  
    getProfilesStream() {
      return this.profiles$.asObservable();
    }
  
    addProfile(name: string, profileImg: File,username:string,password:string,surname:string,email:string,address:string,phone:string): void {
      const fd = new FormData();
      fd.append("name", name);
      fd.append("profileImg", profileImg);
       fd.append('username', username)
      fd.append('password', password)
      fd.append('name', name) 
      fd.append('surname',surname)
      fd.append('email', email)
      fd.append('address', address)
      fd.append('phone', phone)
      this.http
        .post<any>(`${environment.apiURL}/user`, fd)
        .subscribe(() => {
        
       
        });
    }

    onSubmitprofile(fd:FormData) {
      const headers = new HttpHeaders().set('Authorization', this.token);
headers.append('Content-Type', 'application/x-www-form-urlencoded');
   
      return this.http.post(`${environment.apiURL}/user`, fd,  {  
        observe: 'response',
    });
    }
}
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ErrorHandler, NgModule } from "@angular/core";
 import { PickerModule } from '@ctrl/ngx-emoji-mart';
 import {MatIconModule} from '@angular/material/icon';

import {
  HttpClientModule,
  HTTP_INTERCEPTORS,
  HttpClient,
} from "@angular/common/http";
import { FormsModule } from '@angular/forms';

import { environment } from '../environments/environment';
import { NguCarouselModule } from '@ngu/carousel';

import {
  NgbNavModule,
  NgbAccordionModule,
  NgbTooltipModule,NgbPaginationModule,
  NgbModule,
} from "@ng-bootstrap/ng-bootstrap";
import { CarouselModule } from "ngx-owl-carousel-o";
import { ScrollToModule } from "@nicky-lenaers/ngx-scroll-to";

import { ExtrapagesModule } from "./extrapages/extrapages.module";

import { LayoutsModule } from "./layouts/layouts.module";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { initFirebaseBackend } from "./authUtils";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { ErrorInterceptor } from "./core/helpers/error.interceptor";
import { JwtInterceptor } from "./core/helpers/jwt.interceptor";
import { FakeBackendInterceptor } from "./core/helpers/fake-backend";
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatTableModule } from '@angular/material/table'  
 import { CdkTableModule } from '@angular/cdk/table';
  import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from "./main/auth/auth.component";
import { MainPageComponent } from "./main/mainpage/mainpage.component";
import { AuthGuard } from "./core/guards/auth.guard";
import { TokenInterceptorService } from "./services/token-interceptor.service";
import { AuthService } from "./main/auth/auth.service";
import { MainPageService } from "./main/mainpage/mainpage.service";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatButtonModule } from "@angular/material/button";
import { MatRippleModule } from "@angular/material/core";
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { ReactiveFormsModule } from '@angular/forms';
import { matTooltipAnimations } from "@angular/material/tooltip";
 import { JwtHelperService, JwtModule } from '@auth0/angular-jwt';






 
if (environment.defaultauth === 'firebase') {
  initFirebaseBackend(environment.firebaseConfig);
} else {
  // tslint:disable-next-line: no-unused-expression
  FakeBackendInterceptor;
}

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, "assets/i18n/", ".json");
}

/* const config: SocketIoConfig = { url: `${environment.apiURL}` };
 */export function tokenGetter() {
  return localStorage.getItem("token");
}
@NgModule({
  declarations: [AppComponent,AuthComponent],
  imports: [
    MatFormFieldModule,MatTableModule,MatPaginatorModule, 
    NguCarouselModule,MatTableModule, CdkTableModule,MatIconModule,
     NguCarouselModule 
     ,FormsModule,
    CdkTableModule,
    BrowserAnimationsModule, MatRippleModule,
    HttpClientModule,FormsModule, 
    RouterModule,BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    NgxSkeletonLoaderModule.forRoot(),
    
    LayoutsModule,MatInputModule,
    AppRoutingModule,
    ExtrapagesModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbTooltipModule,
    ScrollToModule.forRoot(),
    NgbModule,MatFormFieldModule,MatPaginatorModule,MatTableModule,
    MatSelectModule,
    MatRadioModule,
    ReactiveFormsModule,JwtModule,JwtModule.forRoot({
      config: {
        tokenGetter: function  tokenGetter() { 
          return localStorage.getItem('token');
          }   
      },
    }),
  ],
 
  bootstrap: [AppComponent],
  providers: [AuthGuard,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true,
    } ,
    AuthService, MainPageService
  ],
})
export class AppModule {}

import { Injectable } from '@angular/core';

import { getFirebaseBackend } from '../../authUtils';

import { User } from '../models/auth.models';
import { BehaviorSubject, Observable } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })

export class AuthenticationService {
  AUTH_API = `${environment.apiURL}/api/auth/`;
  AUTH_API2=`${environment.apiURL}/user`; 
  private userSubject: BehaviorSubject<User>;
  public user: Observable<User>;
   httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };
  constructor(private http: HttpClient) {
    this.userSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('user')));
    this.user = this.userSubject.asObservable();
  }
  public get userValue(): User {
    return this.userSubject.value;
}
  /**
   * Returns the current user
   */
  public currentUser(): User {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  /**
   * Performs the auth
   * @param username
   * @param password password of user
   */
  login(username: string, password: string): Observable<any> {
    return this.http.post(this.AUTH_API + 'login', {
      username,
      password
    }, this.httpOptions);
  }


  /**
   * Performs the register
   * @param email email
   * @param password password
   */
  register(email: string, password: string) {
    return getFirebaseBackend().registerUser(email, password).then((response: any) => {
      const user = response;
      return user;
    });
  }

  /**
   * Reset password
   * @param email email
   */
  resetPassword(email: string) {
    return getFirebaseBackend().forgetPassword(email).then((response: any) => {
      const message = response.data;
      return message;
    });
  }
  forgotPassword(email: any) {
    console.log(email);
    return this.http.post<any>(this.AUTH_API2  + 'reset-password-email' , { email })
      .pipe(map(res => {
        // login successful if there's a jwt token in the response
        console.log(res)
      }
      ));
  }

  updatePassword(password: any, email: any, token: any) {
    return this.http.put(this.AUTH_API + `/update-forget/${token}`, { password, email })
  }

  isLoggedIn() {

    return !!localStorage.getItem('token')

  }
  /**
   * Logout the user
   */
  logout() {
    localStorage.clear();
  }

  getCurentUser() {

    if (localStorage.getItem('currentUser') === "undefined") {
      console.log("Item does not exist in localstoarge");
    }
    else {
      var myStr = localStorage.getItem('currentUser');
      if (myStr === null || myStr === 'undefined') {
        myStr = "";
      }
      return myStr;

    }

  }
}

